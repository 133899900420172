import * as React from "react";
import { graphql } from "gatsby";

import { FormattedMessage } from "gatsby-plugin-intl";

const NotFoundPage = ({ data, location }) => {
  return (
    <h1>
      <FormattedMessage id="404_message" defaultMessage="404: Not Found" />
    </h1>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
